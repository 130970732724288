<template>
  <section class="header">
    <div class="container-fluid" v-if="header.full_image == true">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="image">
                    <img :src="header.image" alt="header" class="full-image">
                    <div class="header_text">
                        <h2 class="title">{{header.title}}</h2>
                        <p class="text">{{header.sub_title}}</p>
                        <button class="btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#downloadModal">{{$t("get_app")}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-else>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
                <h2 class="title">{{header.title}}</h2>
                <p class="text">{{header.sub_title}}</p>
                <button class="btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#downloadModal">{{$t("get_app")}}</button>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <img :src="header.image" alt="header">  
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "header-home",
    data(){
        return {
            header: {}
        }
    },
    methods: {
        fetchHeader() {
            axios.get("/fetch_header").then(({ data }) => {
                this.header = data.data;
                // console.log(this.header);
            });
        },
    },
    created(){
        this.fetchHeader();
    }
}
</script>

<style>

</style>