<template>
  <div class="opinions">
    <div class="container">
      <p class="title_section">{{$t("opinions_student")}}</p>
      <h4 class="title">{{opinion.title}}</h4>
      <p class="text">{{opinion.sub_title}}</p>
      <swiper
        :spaceBetween="30"
        :slidesPerGroup="1"
        :loop="true"
        :loopFillGroupWithBlank="true"
        :navigation="true"
        :modules="modules"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          300: {
            slidesPerView: 1,
            // slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 1,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 3,
            loopFillGroupWithBlank: true,
          },
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(opinion, index) in opinion.audiences" :key="index">
          <div class="card_opinions" data-aos="zoom-in-up" data-aos-duration="1000">
            <div class="d-flex align-items-center position-relative">
                <img :src="opinion.image" alt="opinions_image">
                <div class="texts">
                    <h6 class="name">{{opinion.name}}</h6>
                    <p class="job_title">{{opinion.content}}</p>
                </div>
                <i class="fa-solid fa-quote-left"></i>
            </div>
            <p class="faculty">{{opinion.description}}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay, Navigation } from "swiper";

import "swiper/css/navigation";
import axios from 'axios';

export default {
  name: "opinions-home",
  data() {
    return {
      status: "",
      opinion: {},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },
  methods: {
    fetchOpinions() {
      axios.get("/fetch_home_audience_opinions").then(({ data }) => {
        this.opinion = data.data;
        // console.log(this.opinion);
      });
    },
  },
  created() {
    this.fetchOpinions();
  },
};
</script>
