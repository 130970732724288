<template>
  <section class="exam">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
                <p class="title_section">{{$t("exams")}}</p>
                <h4 class="title">{{exam.title}} </h4>
                <p class="text">{{exam.sub_title}}</p>
                <ul class="features">
                    <li class="feature" v-for="(feature, index) in exam.features" :key="index" data-aos="fade-up"
                        data-aos-anchor-placement="bottom-center">{{feature.title}}</li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
                <img :src="exam.image" class="img_exam" alt="exam" data-aos="zoom-in" data-aos-duration="1000">
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "exam-home",
    data(){
        return {
            exam: {}
        }
    },
    methods: {
        fetchExam() {
            axios.get("/fetch_exams_section").then(({ data }) => {
                this.exam = data.data;
                // console.log(this.about);
            });
        },
    },
    created(){
        this.fetchExam();
    }
}
</script>

<style>

</style>