<template>
    <div class="lessons">
        <h5 class="title">{{ $t("lessons") }} ({{ lessons?.length }})</h5>
        <ul class="lessons_card">
          <li class="lesson_card" v-for="(lesson, index) in lessons" :key="index">
                <router-link class="lesson_check" :to="`/lesson/${lesson.id}`" v-if="lesson.available">
                    <p class="name">
                        <i class="fa-solid fa-video"></i>
                        {{ lesson.name }}
                    </p>
                    <span class="description">{{ lesson.description.substring(0, 150) }}</span>
<!--                    <div class="time">-->
<!--                        <i class="fa-regular fa-clock"></i>-->
<!--                        {{ lesson.duration }} {{ $t("minute") }}-->
<!--                    </div>-->
                </router-link>
                <div class="lesson_check disabled" v-tooltip.top="'هذه الحصه غير متاحه لك'" v-else>
                    <p class="name">
                        <i class="fa-solid fa-video"></i>
                        {{ lesson.name }}
                    </p>
                    <span class="description">{{ lesson.description.substring(0, 150) }}</span>
<!--                    <div class="time">-->
<!--                        <i class="fa-regular fa-clock"></i>-->
<!--                        {{ lesson.duration }} {{ $t("minute") }}-->
<!--                    </div>-->
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "lessons-card",
    props: {
        lessons: Array,
    },
}
</script>