<template>
  <div class="partners">
    <div class="container">
      <p class="title_section">{{$t("success_partners")}}</p>
      <h4 class="title">{{partner.title}}</h4>
      <p class="text">{{partner.sub_title}}</p>
      <swiper
        :spaceBetween="30"
        :slidesPerGroup="1"
        :loop="true"
        :loopFillGroupWithBlank="true"
        :navigation="true"
        :modules="modules"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          300: {
            slidesPerView: 1,
            // slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 2,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 3,
            loopFillGroupWithBlank: true,
          },
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(lecturer, index) in partner.lecturers" :key="index" data-aos="fade-up"
                      data-aos-duration="1000">
          <div class="card_partners">
            <img :src="lecturer.image" alt="partners_image">
            <h5 class="name">{{lecturer.name}}</h5>
            <p class="job_title">{{lecturer.education}}</p>
            <p class="faculty">{{lecturer.description}}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay, Navigation } from "swiper";

import "swiper/css/navigation";
import axios from 'axios';

export default {
  name: "Partners-home",
  data() {
    return {
      status: "",
      partner: {
        lecturers: []
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },
  methods: {
    fetchPartners() {
      axios.get("/fetch_home_lecturers").then(({ data }) => {
        this.partner = data.data;
        // console.log(this.partner);
      });
    },
  },
  created() {
    this.fetchPartners();
  },
};
</script>
