<template>
  <section class="breadcrumb">
    <div class="container">
      <h2 class="title">{{ $t("courses") }}</h2>
    </div>
  </section>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="courses courses_page">
    <div class="container">
      <div class="row">
        <courseCard :courses="courses.data" />
      </div>
      <div class="row">
        <pagination
          :data="courses"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchCourses"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import courseCard from "@/components/include/cards/courseCard.vue";
import pagination from "laravel-vue-pagination";
import Loading from "@/components/include/LottieFiles/loading.vue";

export default {
  name: "courses-page",
  data() {
    return {
      courses: {},
      loading: true,
    };
  },
  components: {
    courseCard,
    pagination,
    Loading,
  },
  methods: {
    fetchCourses(page = 1) {
      axios
        .get(`/fetch_courses?page=${page}`)
        .then(({ data }) => {
          this.loading = true;
          this.courses = data.data;
          // console.log(this.header);
        })
        .catch((error) => {
          return error;
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.fetchCourses();
  },
};
</script>
