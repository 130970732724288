<template>
    <section class="auth">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-6 col-md-12 col-12">
                  <div class="image_auth">
                      <img src="@/assets/media/login.png" alt="login">
                  </div>
              </div>
              <div class="col-lg-6 col-md-12 col-12">
                <!-- <div class="form">
                    <form-wizard @on-complete="register">
                        <tab-content title="Personal details">
                            My first tab content
                        </tab-content>
                        <tab-content title="Additional Info" >
                            My second tab content
                        </tab-content>
                    </form-wizard>
                </div> -->
                <form class="form" @submit.prevent="register">
                    <h2>{{ $t("create_new_account") }}</h2>
                    <p>{{ $t("create_new_account_text") }}</p>
                    <div class="form-group">
                        <i class="fa-solid fa-user icon"></i>
                        <input type="text" name="" id="name" v-model="Form.name" :class="{ 'focused': Form.name }" class="form-control">
                        <label for="name">{{ $t("full_name") }}</label>
                    </div>
                    <div class="form-group">
                        <i class="fa-solid fa-phone icon"></i>
                        <input type="text" name="" id="mobile_number" class="form-control" v-model="Form.phone" :class="{ 'focused': Form.phone }" @input="handleInput">
                        <label for="mobile_number">{{ $t("mobile_number") }}</label>
                    </div>
                    <div class="form-group">
                        <i class="fa-solid fa-envelope icon"></i>
                        <input type="email" name="" id="email" class="form-control" v-model="Form.email" :class="{ 'focused': Form.email }" @input="validateEmail">
                        <label for="email">{{ $t("email") }}</label>
                    </div>
                    <div class="form-group">
                        <i class="fa-solid fa-lock icon"></i>
                        <input :type="passwordFieldType" name="" id="password" v-model="Form.password" :class="{ 'focused': Form.password }" class="form-control">
                        <label for="password">{{ $t("password") }}</label>
                        <button class="btn show_password" type="button" @click="switchVisibility" v-html="eye"></button>
                    </div>
                    <button type="submit" class="btn next_button BlueButton" :disabled="disableButton">{{ $t("register") }}</button>
                    <p class="create_new_register">{{ $t("already_have_account") }}<router-link to="/login">{{ $t("login") }}</router-link></p>
                    <div v-if="full_website_data_register">
                        <div class="gender">
                            <h6>{{ $t("gender") }}</h6>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="male" value="1" v-model="Form.type" checked>
                                <label class="form-check-label" for="male">
                                    {{$t("male")}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="2" v-model="Form.type" id="female">
                                <label class="form-check-label" for="female">
                                    {{$t("female")}}
                                </label>
                            </div>
                        </div>
                        <div class="form-input">
                            <label for="">{{$t("university")}}</label>
                            <select class="form-control" v-model="Form.university_id" @change="fetchFacultyId(Form.university_id)">
                                <option v-for="(university, index) in universities" :key="index" :value="university.id">{{ university.title }}</option>
                            </select>
                        </div>
                        <div class="form-input">
                            <label for="">{{$t("faculty")}}</label>
                            <select class="form-control" v-model="Form.university_faculty_id" @change="fetchCategoryId(Form.university_faculty_id)">
                                <option v-for="(faculty, index) in faculties" :key="index" :value="faculty.id">{{ faculty.title }}</option>
                            </select>
                        </div>
                        <div class="form-input">
                            <label for="">{{$t("ufd_id")}}</label>
                            <select class="form-control" v-model="Form.ufd_id" @change="fetchGroupId(Form.ufd_id)">
                                <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.title }}</option>
                            </select>
                        </div>
                        <div class="form-input">
                            <label for="">{{$t("ufdl_id")}}</label>
                            <select class="form-control" v-model="Form.ufdl_id">
                                <option v-for="(group, index) in groups" :key="index" :value="group.id">{{ group.title }}</option>
                            </select>
                        </div>
                    </div>
                </form>
              </div>
          </div>
      </div>
    </section>
  </template>
  
<script>
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
// import {FormWizard,TabContent} from "vue3-form-wizard";
// import 'vue3-form-wizard/dist/style.css'

export default {
    name:"register-page",
    data() {
        return {
            baseDeviceName: 'Learno', // You can set your base device name here
            uniqueDeviceName: '',
            passwordFieldType: "password",
            eye: "<i class='fa-solid fa-eye'></i>",
            Form: {
                name: "",
                phone: "",
                email: "",
                password: "",
                type: 1,
                education_type_id: 2,
                university_id: "",
                university_faculty_id: "",
                ufd_id: "",
                ufdl_id: "",
            },
            emailError: "",
            disableButton: false,
            universities: [
                {
                    children: []
                }
            ],
            faculties: [],
            categories: [],
            groups: [],
            full_website_data_register: JSON.parse(localStorage.getItem("web_status")).full_website_data_register
        };
    },
    // components: {
    //     FormWizard,
    //     TabContent,
    // },
    mounted() {
        this.generateUniqueDeviceName();
        this.fetchUniversities();
    },
    methods: {
        generateUniqueDeviceName() {
            const uniqueId = uuidv4();
            this.uniqueDeviceName = `${this.baseDeviceName}-${uniqueId}`;
        },
        handleInput() {
            // Remove non-numeric characters from the input
            this.Form.phone = this.Form.phone.replace(/\D/g, '');
        },
        validateEmail() {
            // Regular expression for email validation
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!this.Form.email) {
                this.emailError = this.$t('Email is required');
            } else if (!emailPattern.test(this.Form.email)) {
                this.emailError = this.$t('Invalid email format');
            } else {
                this.emailError = ''; // Reset the error message if email is valid
            }
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.eye = this.eye == "<i class='fa-solid fa-eye'></i>" ? "<i class='fa-solid fa-eye-slash'></i>" : "<i class='fa-solid fa-eye'></i>"
        },
        fetchUniversities() {
            axios.get("/fetch_universities").then(({ data }) => {
                this.universities = data.data;
                // console.log(this.universities);
            });
        },
        fetchFacultyId() {
            // Find the selected university based on university_id
            const selectedUniversity = this.universities.find(university => university.id === this.Form.university_id);

            if (selectedUniversity) {
                // Now you can access the children of the selected university
                this.faculties = selectedUniversity.children;
                // console.log(this.faculties); // Do whatever you need with the children
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Selected university not found",
                });
            }
        },
        fetchCategoryId() {
            // Find the selected university based on university_faculty_id
            const selectedFaculty = this.faculties.find(faculty => faculty.id === this.Form.university_faculty_id);

            if (selectedFaculty) {
                // Now you can access the children of the selected university
                this.categories = selectedFaculty.children;
                // console.log(this.categories); // Do whatever you need with the children
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Selected university not found",
                });
            }
        },

        fetchGroupId() {
            // Find the selected university based on ufd_id
            const selectedCatgory = this.categories.find(category => category.id === this.Form.ufd_id);

            if (selectedCatgory) {
                // Now you can access the children of the selected university
                this.groups = selectedCatgory.children;
                // console.log(this.faculties); // Do whatever you need with the children
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Selected university not found",
                });
            }
        },
        async register() {
            this.disableButton = true; //the disableButton begin
            
            const formData = new FormData();
            if (this.Form.name) {
                formData.append('name', this.Form.name);
            }
            if (this.Form.phone) {
                formData.append('phone', this.Form.phone);
            }
            if (this.Form.email) {
                formData.append('email', this.Form.email);
            }
            if (this.Form.password) {
                formData.append('password', this.Form.password);
            }
            formData.append('type', 1);
            formData.append('education_type_id', 2);
            if (this.Form.university_id) {
                formData.append('university_id', this.Form.university_id);
            }
            if (this.Form.university_faculty_id) {
                formData.append('university_faculty_id', this.Form.university_faculty_id);
            }
            if (this.Form.ufd_id) {
                formData.append('ufd_id', this.Form.ufd_id);
            }
            if (this.Form.ufdl_id) {
                formData.append('ufdl_id', this.Form.ufdl_id);
            }
            if (this.uniqueDeviceName) {
                formData.append('device_name', this.uniqueDeviceName);
            }

            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (this.Form.name === "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("name field is empty"),
                });
                this.disableButton = false;
            } else if (this.Form.phone === "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("phone field is empty"),
                });
                this.disableButton = false;
            } else if (this.Form.email === "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("email field is empty"),
                });
                this.disableButton = false;
            }
            else if(!emailPattern.test(this.Form.email)) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("Invalid email format"),
                });
                this.disableButton = false;
            } else if (this.Form.password === "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("password field is empty"),
                });
                this.disableButton = false;
            } else if (this.checkTerms === false) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("please_checked_terms"),
                });
                this.disableButton = false;
            } else {
                try {
                let response = await this.$store.dispatch("Register", formData);
                if (response.data.status == true) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: response.data.message,
                    });
                    this.Form = {
                        phone: "",
                        email: "",
                        name: "",
                        password: "",
                    };
                    location.reload();
                } else if (response.data.status == false) {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: response.data.message,
                    });
                    this.disableButton = false;
                }
                } catch (error) {
                    Swal.fire({
                        title: "",
                        text: error.response.data.message,
                        icon: "error",
                    });
                    this.disableButton = false;
                } finally {
                    this.disableButton = false;
                }
            }
        },
    },
}
</script>