<template>
  <section class="about" id="about">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12" data-aos="fade-right" data-aos-duration="1000">
                <p class="title_section">{{$t("about_us")}}</p>
                <h4 class="title">{{about.title}} </h4>
                <p class="text">{{about.sub_title}}</p>
                <ul class="features">
                    <li class="feature" v-for="(feature, index) in about.features" :key="index">{{feature.title}}</li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-12 col-12" data-aos="fade-left" data-aos-duration="3000">
                <Youtube :videoId="videoId" v-if="about.video" />
                <img :src="about.image" class="img_about" alt="about" v-else>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Youtube from '@/components/include/video/youtube.vue';

export default {
    name: "about-home",
    data(){
        return {
            about: {},
            // videoId: "",
            youtubeUrl: "",
            logo: JSON.parse(localStorage.getItem("web_status"))?.logo
        }
    },
    methods: {
        fetchAbout() {
            axios.get("/fetch_about").then(({ data }) => {
                this.about = data.data;
                this.youtubeUrl = data.data.video;
                // console.log(this.youtubeUrl);
            });
        },

        extractVideoId(url) {
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const matches = url.match(regex);
            return matches ? matches[1] : '';
        },
    },
    computed: {
        videoId() {
            return this.extractVideoId(this.youtubeUrl);
        },
    },
    created(){
        this.fetchAbout();
    },
    components: {
        Youtube
    },
}
</script>

<style>

</style>