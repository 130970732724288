<template>
    <Player theme="dark" id="myVideo" :style="`--vm-player-theme: ${primary_color}`" class="content">
        <Youtube :showFullscreenControl="false" :videoId="videoId" />
        <Ui>
            <DefaultSettings />
            <Controls>
                <PlaybackControl />
                <VolumeControl />
                <ScrubberControl />
                <SettingsControl />
            </Controls>
            <Poster />
            <ClickToPlay />
            <LoadingScreen>
                <img :src="logo" class="image_loading" alt="logo">
            </LoadingScreen>
        </Ui>
        <div class="tapSidesToSeek">
            <div class="spacer"></div>
            <div class="tapTarget"></div>
            <div class="spacer"></div>
        </div>
    </Player>
</template>

<script>
import {
    Player, Youtube, Ui, LoadingScreen, Controls, PlaybackControl, VolumeControl, SettingsControl, ScrubberControl, Poster, DefaultSettings, ClickToPlay
} from '@vime/vue-next';
import '@/assets/css/default.css';

export default {
    name: "youtube-video",
    props: {
        videoId: String
    },
    data() {
        return {
            primary_color: JSON.parse(localStorage.getItem("web_status"))?.primary_color,
            logo: JSON.parse(localStorage.getItem("web_status"))?.logo,
        }
    },
    components: {
        Player,
        Youtube,
        Ui,
        Controls,
        PlaybackControl,
        VolumeControl,
        SettingsControl,
        ScrubberControl,
        Poster,
        DefaultSettings,
        ClickToPlay,
        LoadingScreen
    }
}
</script>

<style>

</style>