<template>
  <section class="application" id="download" data-aos="fade-up"
           data-aos-anchor-placement="top-center" data-aos-duration="1000">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12 d-flex flex-column justify-content-center">
          <p class="title_section">{{$t("download_application")}}</p>
          <h2 class="title">{{application.title}}</h2>
          <p class="text">{{application.sub_title}}</p>
          <div class="buttons">
            <a :href="application.android" class="link" target="_blank">
              <img src="../../../assets/media/google_white.png" alt="google">
            </a>
            <a :href="application.ios" class="link" target="_blank">
              <img src="../../../assets/media/apple_white.png" alt="apple">
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <img :src="application.image" class="application_image" :alt="application.title">
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import axios from 'axios';

export default {
  name: "application-home",
  data() {
    return {
      application: {},
    };
  },
  methods: {
    fetchApplication() {
      axios.get("/fetch_download_app_section").then(({ data }) => {
        this.application = data.data;
        // console.log(this.application);
      });
    },
  },
  created() {
    this.fetchApplication();
  },
};
</script>
