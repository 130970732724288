<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img :src="logo" alt="logo" class="logo">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link overline-right-underline-left active" to="/">{{ $t("home") }}</router-link>
          </li>
          <li class="nav-item">
            <a href="/#about" class="nav-link overline-right-underline-left">{{ $t("about_us") }}</a>
          </li>
          <li class="nav-item">
            <router-link to="/courses" class="nav-link overline-right-underline-left">{{ $t("courses") }}</router-link>
          </li>
          <li class="nav-item">
            <a href="/#contact" class="nav-link overline-right-underline-left">{{ $t("contact_us") }}</a>
          </li>
        </ul>
        <div class="dropdown" v-if="auth">
          <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="@/assets/media/user.png" alt="user">
            {{ user?.name }}
          </button>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" to="/my_courses">{{ $t("my_courses") }}</router-link></li>
            <li><router-link class="dropdown-item" to="/exams">{{ $t("my_exams") }}</router-link></li>
            <!-- <li><router-link class="dropdown-item" to="/history">{{$t("views_history")}}</router-link></li>
          <li><router-link class="dropdown-item" to="/my_order">{{$t("notifications")}}</router-link></li> -->
            <!-- <li><router-link class="dropdown-item" to="/profile">{{$t("profile_setting")}}</router-link></li> -->
            <button type="button" class="btn dropdown-item logout_btn" @click="removeToken">{{ $t("logout") }}</button>
          </ul>
        </div>
        <div class="buttons_auth" v-else>
          <router-link to="/login" class="btn PrimaryButton">{{ $t("login") }}</router-link>
          <router-link to="/register" class="btn SecondButton">{{ $t("create_new_register") }}</router-link>
        </div>
        <ButtonLang />
      </div>
    </div>
  </nav>
</template>

<script>
import ButtonLang from "@/components/include/buttons/LangButton.vue";
import Swal from "sweetalert2";

export default {
  name: "navbar-layout",
  props: ["logo"],
  data() {
    return {
      auth: false,
      user: JSON.parse(localStorage.getItem("user"))
    }
  },
  components: {
    ButtonLang,
  },
  methods: {
    async removeToken() {
      Swal.fire({
        title: this.$t("check_logout"),
        text: this.$t("are_you_sure_logout"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_logout"),
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          location.reload();}
      });
    },
  },
  created() {
    this.auth = this.$store.getters['isAuthenticated'];
  }
};
</script>

<style scoped></style>