<template>
  <section class="courses" id="courses">
    <div class="container">
        <div class="d-flex justify-content-between align-items-center">
            <p class="title_section">{{$t("courses")}}</p>
            <router-link class="btn show_more" to="/courses">{{$t("show_more")}} > </router-link>
        </div>
        <h4 class="title">{{course.title}}</h4>
        <p class="text">{{course.sub_title}}</p>

        <courseCard :courses="course.courses" />
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import courseCard from "../../include/cards/courseCard.vue";

export default {
    name: "courses-home",
    data(){
        return {
            course: {}
        }
    },
    components: {
        courseCard
    },
    methods: {
        fetchCourses() {
            axios.get("/fetch_home_courses").then(({ data }) => {
                this.course = data.data;
                // console.log(this.header);
            });
        },
    },
    created(){
        this.fetchCourses();
    }
}
</script>

<style>

</style>