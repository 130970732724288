<template>
    <div class="mt-3">
        <h5 class="title">{{ $t("videos") }} {{ sessions?.length }}</h5>
        <ul class="lessons_card">
            <li class="lesson_card" v-for="(session, index) in sessions" :key="index">
                <div class="click" @click="showSession(session)" v-if="session.available">
                    <p class="name">
                        <i class="fa-solid fa-video"></i>
                        {{ session.name }}
                    </p>
                    <div class="time">
                        <i class="fa-regular fa-clock"></i>
                        {{ session.duration }} {{ $t("minute") }}
                    </div>
                </div>
                <div class="click disabled" v-tooltip.top="'هذه الحصه غير متاحه لك'" v-else>
                    <p class="name">
                        <i class="fa-solid fa-video"></i>
                        {{ session.name }}
                    </p>
                    <div class="time">
                        <i class="fa-regular fa-clock"></i>
                        {{ session.duration }} {{ $t("minute") }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "sessions-card",
    props: {
        sessions: Array
    },
    methods: {
        showSession(session) {
            // Emit an event with the session details
            this.$emit("session-clicked", session);
            // console.log(video)
        }
    }
}
</script>