<template>
    <section class="faq" id="faq">
        <div class="container">
            <p class="title_section">{{$t("faq")}}</p>
            <h4 class="title">{{questions.title}}</h4>
            <p class="text">{{questions.sub_title}}</p>
            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-12" v-for="(question, index) in questions.questions" :key="index">
                        <div class="accordion-item" data-aos="fade-up"
                             data-aos-anchor-placement="bottom-center">
                            <h2 class="accordion-header" :id="`flush-heading${index}`">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#flush-collapseOne${index}`" aria-expanded="false" :aria-controls="`flush-collapseOne${index}`">
                                {{question.question}}
                            </button>
                            </h2>
                            <div :id="`flush-collapseOne${index}`" class="accordion-collapse collapse" :aria-labelledby="`flush-heading${index}`" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">{{question.answer}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "faq-home",
    data(){
        return {
            questions: {}
        }
    },
    methods: {
        fetchFaq() {
            axios.get("/fetch_home_common_questions").then(({ data }) => {
                this.questions = data.data;
                // console.log(this.about);
            });
        },
    },
    created(){
        this.fetchFaq();
    }
}
</script>

<style>

</style>