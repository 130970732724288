<template>
    <div class="header_course">
        <div class="container">
            <div class="breadcrumb">
                <router-link to="/">{{ $t("home") }} / </router-link>
                <router-link to="/courses">{{ $t("courses") }} / </router-link>
                <span>{{ course.course.name }}</span>
            </div>
            <h2 class="title_course">{{ course.course.name }}</h2>
            <p class="description_course">{{ course?.course?.description?.substring(0, 100) + ' ...' }}</p>
            <ul class="statistics">
                <li><i class="fa-brands fa-youtube"></i>{{ course.course.lessons_number }} {{ $t("lessons") }}</li>
                <li><i class="fa-solid fa-list"></i>{{ course.course.sessions_number }} {{ $t("sessions") }}</li>
                <li><i class="fa-regular fa-newspaper"></i>{{ course.course.exams_count }} {{ $t("exams") }}</li>
            </ul>
            <div class="course_card_price">
                <div class="image">
                    <img :src="course.course.image" alt="course image">
                    <i class="fa-solid fa-circle-play play"></i>
                </div>
                <div class="price">
                    <p class="title">{{ $t("course_price") }}</p>
                    <p class="text">{{ course.course.price }} {{ course.course.currency }}</p>
                </div>
                <button type="button" class="btn BlueButton" v-if="!course.course.available" @click="subscribeCourse">{{ $t("subscribe_now") }}</button>
            </div>
        </div>
    </div>
    <div class="course_details" :class="{eventNone : !auth}">
        <div class="container">
            <div class="course_data">
                <h5 class="title">{{ $t("course_details") }}</h5>
                <p class="description_course">{{ course.course.description}}</p>
            </div>
            <Lessons v-if="course.lessons.length" :lessons="course.lessons" />
            <Exams v-if="course.exams.length" :exams="course.exams" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Lessons from '@/components/include/course/lessons.vue';
import Exams from '@/components/include/course/exams.vue';

export default {
  name: "courses-page",
  data() {
    return {
      course: {
        course: {
            name: "",
            description: "",
            lessons_number: "",
            sessions_number: "",
            exams_count: ""
        },
        lessons: [],
        exams: [],
        documents: [],
        auth: false,
      },
      auth: this.$store.getters["isAuthenticated"],
      loading: true,
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id
    };
  },
  components: {
    Lessons,
    Exams
  },
  methods: {
    fetchCourseDetails() {
      this.loading = true;
      const formData = new FormData();
      if(this.auth) {
        formData.append('user_id', this.user_id);
      }
      formData.append('course_id', this.$route.params.id);
      axios
          .post(`/fetch_course_details`, formData)
          .then(({ data }) => {
            this.loading = false;
            this.course = data.data;
          //   console.log(this.course);
          })
          .catch((error) => {
          return error;
          // this.$router.push({ path: "/teachers" });
          })
          .finally(() => (this.loading = false));
    },

    subscribeCourse() {
        const subscribe_data = {
            course_id: this.$route.params.id,
            // group_id: null,
            type: 0,
            join_type: 0,  // 0 => online , 1 => center , 2 => online && center 
            payment_method_id: 1
        }
        axios
            .post(`/subscribe_course`, subscribe_data)
            .then(({ data }) => {
                this.loading = true;
                this.course = data.data;
                // console.log(data)
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: data.message,
                });
                //   console.log(this.course);
            })
            .catch((error) => {
                // console.log(error)
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: error.response.data.message,
                });
                return error;
            })
            .finally(() => (this.loading = false));
    },
  },
  created() {
    this.fetchCourseDetails();
    this.auth = this.$store.getters['isAuthenticated'];
  },
};
</script>
