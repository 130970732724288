<template>
  <section class="middle">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12" data-aos="flip-right"
                 data-aos-easing="ease-out-cubic"
                 data-aos-duration="1000">
                <img :src="middle.image" :alt="middle.title">
            </div>
            <div class="col-lg-6 col-12" data-aos="flip-left"
                 data-aos-easing="ease-out-cubic"
                 data-aos-duration="1000">
                <h4 class="title">{{middle.title}}</h4>
                <p class="text">{{middle.sub_title}}</p>
                <button class="btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#downloadModal">{{$t("get_app")}}</button>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    name: "middle-home",
    data(){
        return {
            middle: {}
        }
    },
    methods: {
        fetchMiddle() {
            axios.get("/fetch_middle_section").then(({ data }) => {
                this.middle = data.data;
                // console.log(this.header);
            });
        },
    },
    created(){
        this.fetchMiddle();
    }
}
</script>


<style>

</style>