<template>
  <section class="statistics" v-if="statistics.length">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-6" v-for="(statistic, index) in statistics" :key="index">
                <div class="card_statistic">
                    <h4 class="title">{{statistic.title}}</h4>
                    <p class="text">{{statistic.sub_title}}</p>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
    name: "statistics-home",
    data(){
        return {
            statistics: []
        }
    },
    methods: {
        fetchStatistics() {
            axios.get("/fetch_statistics").then(({ data }) => {
                this.statistics = data.data;
                // console.log(this.about);
            });
        },
    },
    created(){
        this.fetchStatistics();
    }
}
</script>

<style>

</style>