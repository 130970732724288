<template>
  <section class="auth">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
                <div class="image_auth">
                    <img src="@/assets/media/login.png" alt="login">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
                <form class="form" @submit.prevent="login">
                    <h2>{{ $t("login") }}</h2>
                    <p>{{ $t("enter_data_success") }}</p>
                    <div class="form-group">
                        <i class="fa-solid fa-phone icon"></i>
                        <input type="text" name="" id="mobile_number" v-model="Form.phone" class="form-control" :class="{ 'focused': Form.phone }" @input="handleInput">
                        <label for="mobile_number">{{ $t("mobile_number") }}</label>
                    </div>
                    <div class="form-group">
                        <i class="fa-solid fa-lock icon"></i>
                        <input type="password" name="" id="password" v-model="Form.password" class="form-control" :class="{ 'focused': Form.password }">
                        <label for="password">{{ $t("password") }}</label>
                        <button class="btn show_password" type="button" @click="switchVisibility" v-html="eye"></button>
                    </div>
                    <router-link to="/forget_password" class="forget_password">{{ $t("forget_password") }}</router-link>
                    <button type="submit" class="btn PrimaryButton" :disabled="disableButton">{{ $t("start_now") }}</button>
                    <p class="create_new_register">{{ $t("i_dont_have_account") }}<router-link to="/register">{{ $t("create_new_register") }}</router-link></p>
                    <!-- {{ uniqueDeviceName  }} -->
                </form>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

export default {
    name:"login-page",
    data() {
        return {
            baseDeviceName: 'Learno', // You can set your base device name here
            uniqueDeviceName: '',
            passwordFieldType: "password",
            eye: "<i class='fa-solid fa-eye'></i>",
            Form: {
                phone: "",
                password: "",
            },
            disableButton: false
        };
    },
    mounted() {
        this.generateUniqueDeviceName();
    },
    methods: {
        generateUniqueDeviceName() {
            const uniqueId = uuidv4();
            this.uniqueDeviceName = `${this.baseDeviceName}-${uniqueId}`;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.eye = this.eye == "<i class='fa-solid fa-eye'></i>" ? "<i class='fa-solid fa-eye-slash'></i>" : "<i class='fa-solid fa-eye'></i>"
        },
        handleInput() {
            // Remove non-numeric characters from the input
            this.Form.phone = this.Form.phone.replace(/\D/g, '');
        },
        async login() {
            this.disableButton = true; //the disableButton begin
            // console.log(this.phone.replace(/\s+/g, ''));
            if (this.Form.phone === "" || this.Form.password === "") {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("Please dictate all fields"),
                });
                this.disableButton = false;
            }
            else {
                const formData = new FormData();
                Object.entries(this.Form).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                formData.append('device_name', this.uniqueDeviceName);
                let response = await this.$store.dispatch("Login", formData);
                try {
                    // console.log(response)
                    if (response.data.status == true) {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: response.data.message,
                        });
                        this.Form = {
                            phone: "",
                            password: "",
                        };
                        this.$router.go('/');
                        this.error = null;
                        this.disableButton = false;
                    }
                    else{
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: response.response.data.message,
                        });
                        this.disableButton = false;
                    }
                }catch (error) {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: response.response.data.message,
                    });
                    this.disableButton = false;
                }
            }
        },
    },
}
</script>

<style>
    
</style>